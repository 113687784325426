.icon {
  vertical-align: middle;
  line-height: 1px;
  font-size: 20px;
}
.icon_header {
  vertical-align: middle;
  line-height: 1px;
  font-size: 26px;
}
.icon, .icon_header {
  margin-right: 5px;
}

.icon:hover {
  color: violet;
}

.close_icon {
  color: #ff6000;
  margin-left: 20px;
  margin-top: 10px;
  margin-bottom: 35px;
  cursor: pointer;
  margin-right: 20px;
}

.grid-container {
  display: grid;
  grid-template-columns: 260px 1fr 1fr 1fr;
  grid-template-rows: 0.2fr 3fr;
  grid-template-areas:
      'sidebar header header header'
      'sidebar main main main';
  height: 100vh;
}

#eatzap-text {
  font-size: 18px;
  width: 70px;
  color: white;
  font-family: 'Poppins';
}

#eatzap-logo {
  height: 60px;
  width: 40px;
  max-width: 100%;
  max-height: 100%;
}

#sidebar {
  grid-area: sidebar;
  height: 100%;
  background-color: #000c23;
  overflow-y: auto;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
}

.sidebar-title {
  display: flex;
  justify-content: space-between;
  align-items: end;
  margin-bottom: 10px;
  margin-top: 20px;
}

.sidebar-title > span {
  display: none;
}

.sidebar-brand {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  margin-left: 5px;
  width: 150px;
  height: 100%;
  font-weight: 700;
}

.sidebar-list {
  padding: 0;
  list-style-type: none;
}

.sidebar-list-item {
  padding: 20px 20px 20px 20px;
  font-size: 18px;
}

.sidebar-list-item:hover {
  border-radius: 5px;
  font-size: 20px;
  background-color: rgba(49, 45, 94, 0.505);
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.sidebar-list-item > a {
  font-size: 14px;
  font-family: 'Poppins';
  text-decoration: none;
  color: #ffffff;
}

.sidebar-list-item:hover > a {
  color: #ff6000;
  margin-left: 5px;
  text-decoration: none;
  transform: translateX(20px);
  transition: transform 0.1s ease, color 0.1s ease;
}

.sidebar-responsive {
  display: inline !important;
  position: absolute;
  /*
    we want the z-index of the sidebar higher so that
    the charts are not showing over the sidebar 
    on small screens
  */
  z-index: 12 !important;
}
