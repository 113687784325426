.kot-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.kot-select-dropdown {
  width: 150px;
}

.kot-modal-top-area {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.kot-modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  overflow: scroll;
  height: 80%;
  width: 80%;
  max-width: 800px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
}

.kot-modal-header select {
  width: 100px;
}

.kot-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.confirmation-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  border-radius: 12px;
  background-color: white;
  border: 1px solid black;
  z-index: 1000;
}

.confirmation-dialog p {
  margin-bottom: 20px;
}

.confirmation-dialog button {
  margin-right: 60px;
}

.kot-modal-body {
  margin-bottom: 20px;
}

.kot-modal-footer {
  display: flex;
  justify-content: flex-end;
}

.kot-modal-header button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.kot-modal-header button:hover {
  background-color: #0056b3;
}

.table-container table {
  width: 100%;
  border-collapse: collapse;
}

.table-container th,
.table-container td {
  padding: 8px;
  border: 1px solid #ddd;
  text-align: left;
}

.table-container th {
  background-color: #f4f4f4;
}

/* Mobile Responsive Styles for PrintKOTModal */
@media screen and (max-width: 768px) {
  .kot-modal-overlay {
    padding: 10px;
  }
  
  .kot-modal-content {
    width: 95%;
    max-width: 100%;
    max-height: 90vh;
    padding: 10px;
  }
  
  .kot-modal-top-area {
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 0;
  }
  
  .kot-modal-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
  
  .kot-modal-header button {
    width: 100%;
    margin-top: 10px;
  }
  
  .kot-selected-dropdown {
    width: 100%;
  }
  
  .kot-modal-header span {
    display: block;
    width: 100%;
    margin: 5px 0;
  }
  
  .kot-modal-header label {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .kot-modal-body {
    overflow-x: auto;
  }
  
  table {
    font-size: 12px;
  }
  
  table th, table td {
    padding: 6px 4px;
  }
  
  /* Hide less important columns on very small screens */
  @media screen and (max-width: 480px) {
    
    .kot-modal-content {
      width: 98%;
      padding: 5px;
    }
    
    .confirmation-dialog {
      width: 90%;
      padding: 15px;
    }
    
    .confirmation-dialog button {
      padding: 8px 12px;
      margin: 5px;
    }
  }
}