table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 15px;
  padding: 10px;
}

thead {
  display: table-header-group;
  /* Fix for modern styling */
}

tbody {
  display: table-row-group;
  /* Fix for modern styling */
}

th,
td {
  background-color: #ffffff; /* Removing this */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* and this will almost bring back the original style */
  border: none;
  padding: 16px;
  text-align: left;
}

th {
  font-size: 15px;
  font-weight: 600;
  color: #333;
  background-color: #f7f9fc;
}

td {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #555;
}

tr {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

tr:hover {
  transform: scale(1.01);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

button {
  color: black;
  font-family: 'Poppins';
  border: none;
  cursor: pointer;
  margin: 5px;
  padding: 10px;
  border-radius: 20px;
  background-color: rgba(0, 66, 99, 0.251);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  /* Added drop shadow */
  transition: transform 0.2s, box-shadow 0.2s;
  /* Smooth transition */
}

button:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
  /* Enhanced shadow on hover */
}

.menu-add-button {
  background-color: purple;
  color: white;
  font-family: 'Poppins';
  float: right;
}

/* CSS for the add popup overlay */
.add-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  /* Ensure the popup appears above other content */
}

/* CSS for the add popup container */
.add-popup {
  background: white;
  padding: 40px;
  border-radius: 8px;
  width: 60%;
  height: 80%;
  max-width: 600px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  /* Make the content inside the popup scrollable */
}

/* Additional CSS for form inside the popup */
.add-popup form {
  display: flex;
  flex-direction: column;
}

.add-popup label {
  margin-top: 10px;
}

.add-popup input[type="text"],
.add-popup input[type="date"],
.add-popup input[type="file"] {
  margin-top: 5px;
  margin-bottom: 15px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.add-popup .toggle-switch {
  display: flex;
  gap: 10px;
}

.add-popup .toggle-switch label {
  margin: 0;
  padding: 0 5px;
}

.food-Image {
  margin-left: 15px;
  margin-right: 15px;
  width: 80px;
  height: 80px;
  object-fit: cover;
}

/* For the Canteen Dropdown*/

.canteen-dropdown {
  appearance: none;
  background-color: transparent;
  border: 2px solid black;
  float: left;
  border-radius: 8px;
  color: #333;
  cursor: pointer;
  font-size: 16px;
  padding: 10px 16px;
  width: 200px;
  outline: none;
  transition: border-color 0.3s, color 0.3s;
}

.canteen-dropdown:hover,
.canteen-dropdown:focus {
  border-color: #ff6000;
}

.canteen-dropdown option {
  background-color: #fff;
  color: #333;
}

/* Custom arrow */
.canteen-dropdown::after {
  content: '\25BC';
  /* Unicode character for downward arrow */
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
}

.delete-button:hover {
  background-color: red;
}

/* Tab bar */
.top-area {
  margin-top: -50px;
  display: relative;
  align-items: center;
  padding: 30px;
  position: relative;
}

.search-box {
  padding: 20px;
}

.canteen-dropdown {
  position: absolute;
  left: 0;
  margin-left: 10px;
}

.tab-bar {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tab-button {
  margin: 0 10px;
  padding: 10px 20px;
  border: none;
  background-color: #f0f0f0;
  cursor: pointer;
  transition: background-color 0.3s;
}

.tab-button.active {
  background-color: #007bff;
  color: white;
}

.tab-button:hover {
  color: #ff6000;
  background-color: #00000030;
}
@media screen and (max-width: 768px) {
  .menu-items-container .top-area {
    padding: 16px;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 50px;
  }

  .top-area {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 10px;
  }

  .canteen-dropdown {
    width: 100%;
    position: relative; /* Changed from absolute */
    left: auto;
    margin-left: 0;
    margin-bottom: 10px;
  }
  
  .menu-add-button {
    width: 100%;
    padding: 12px;
    background-color: purple;
    color: white;
    border-radius: 20px;
    text-align: center;
    display: block;
  }

  .menu-items-container .search-bar {
    padding: 0 16px;
    margin-bottom: 16px;
  }

  .menu-items-container table tbody tr td:nth-last-child(8)::before {
    content: "Price: ";
  }

  .menu-items-container table tbody tr td:nth-last-child(7)::before {
    content: "Stock: ";
  }

  .menu-items-container table tbody tr td:nth-last-child(6)::before {
    content: "Category: ";
  }

  .menu-items-container table tbody tr td:nth-last-child(5)::before {
    content: "Availability: ";
  }

  .menu-items-container table tbody tr td:nth-last-child(4) {
    display: none;
  }

  .menu-items-container table tbody tr td:nth-last-child(3) {
    display: none;
  }

  .menu-items-container table tbody tr td:nth-last-child(2) {
    display: none;
  }

  .menu-items-container table tbody tr td:nth-last-child(9) {
    font-weight: bold;
    font-size: 1.2rem;
  }
  
  .menu-items-container table, 
  .menu-items-container thead, 
  .menu-items-container tbody, 
  .menu-items-container tr, 
  .menu-items-container th, 
  .menu-items-container td {
      display: block;
  }
  
  .menu-items-container thead {
      display: none;
  }  
  .menu-items-container tbody tr {
    margin-bottom: 20px;
    background: white;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }

  .menu-items-container td {
    padding: 12px 16px;
    text-align: left;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #eee;
  }

  .menu-items-container .food-Image {
    width: 100%;
    height: 200px;
    margin: 0;
    object-fit: cover;
    border-radius: 8px 8px 0 0;
  }
  
  .menu-items-container td:first-child {
    padding: 0;
    border-bottom: none;
  }

  .menu-items-container td:not(:first-child):before {
    content: attr(data-label);
    font-weight: 600;
    width: 40%;
    margin-right: 12px;
    color: #666;
  }

  .menu-items-container td:last-child {
    display: flex;
    justify-content: space-around;
    padding: 16px;
    border-bottom: none;
    background: #f8f9fa;
  }

  .menu-items-container td:last-child button {
    padding: 10px 24px;
    border-radius: 6px;
    font-size: 16px;
  }

  .menu-items-container .delete-button {
    background: #dc3545;
    color: white;
  }
  
  .menu-items-container td[data-label="Availability"] {
    padding: 8px 16px;
  }

  .menu-items-container td[data-label="Availability"]:before {
    content: "•";
    width: auto;
    margin-right: 8px;
    color: inherit;
  }

  .menu-items-container .pagination {
    padding: 16px;
    gap: 8px;
    flex-wrap: wrap;
    justify-content: center;
  }

  .menu-items-container .pagination li a {
    padding: 8px 12px;
    font-size: 14px;
  }

  .modal-content {
    width: 90%;
    margin: 20px auto;
    padding: 16px;
  }

  .form-group {
    margin-bottom: 16px;
  }

  .form-group input,
  .form-group select {
    width: 100%;
    padding: 10px;
  }

  .modal-buttons {
    flex-direction: column;
    gap: 12px;
  }

  .modal-buttons button {
    width: 100%;
    padding: 12px;
  }
}

@media screen and (max-width: 480px) {
  .menu-items-container td {
    padding: 10px 12px;
    font-size: 14px;
  }

  .menu-items-container .food-Image {
    height: 160px;
  }

  .menu-items-container td:last-child button {
    padding: 8px 16px;
    font-size: 14px;
  }

  .menu-items-container .pagination li a {
    padding: 6px 10px;
    font-size: 13px;
  }
}