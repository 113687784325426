/* SearchBar.css */

/* SearchBar.css - Modern Style */
.search-containerr {
  position: relative;
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  width: 100%;
}

.search-inputt {
  width: 100%;
  max-width: 600px;
  padding: 14px 20px;
  font-size: 16px;
  border: none;
  border-radius: 12px;
  outline: none;
  background-color: #f5f5f7;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.search-inputt:focus {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

.search-inputt::placeholder {
  color: #a0a0a0;
  font-weight: 300;
}


/* Responsive adjustments */
@media screen and (max-width: 768px) {
  .search-inputt {
    max-width: 90%;
  }
  
  .search-containerr::before {
    left: 8%;
  }
}

/* If you want to include a search button */
/* .search-button {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 20px;
    padding: 8px 16px;
    cursor: pointer;
    outline: none;
    transition: background-color 0.3s ease;
  }
  
  .search-button:hover {
    background-color: #0056b3;
  } */