.admin-page {
    position: relative;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .background-image img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .login-container {
    background: rgba(255, 255, 255, 0.8);
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    max-width: 400px;
    width: 100%;
  }
  
  .login-container h2 {
    margin-bottom: 1rem;
    color: #333;
  }
  
  .login-input {
    display: block;
    width: 100%;
    padding: 1rem;
    margin-bottom: 1rem;
    border: none;
    border-radius: 5px;
    font-size: 1.2rem;
    box-sizing: border-box; /* Ensure padding does not affect the width */
  }
  
  .login-button {
    padding: 1rem 2rem;
    font-size: 1.2rem;
    border: none;
    border-radius: 5px;
    background-color: #ff6347;
    color: white;
    cursor: pointer;
    width: 100%;
  }
  
  .login-button:disabled {
    background-color: #ff9980;
    cursor: not-allowed;
  }
  
  .error-message {
    color: red;
    margin-top: 1rem;
  }  