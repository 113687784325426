.main-container {
    grid-area: main;
    overflow-y: auto;
    padding: 20px 20px;
    color: rgba(0, 0, 0, 0.95);
    font-size: 11px;
    font-family: 'Poppins';
  }
  
  .main-title {
    display: flex;
    justify-content: space-between;
  }
  
  .main-cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;
    margin: 15px 0;
  }
  
  .card {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 8px 15px;
    border-radius: 30px;
  }
  
  .card h1{
    color: #ff6000;
    font-size: 35px;
    padding: 20px;
  }
  
  .card:first-child {
    background-color: #82aeff43;
  }
  
  .card:nth-child(2) {
    background-color: #82aeff43;
  }
  
  .card:nth-child(3) {
    background-color: #82aeff43;
  }
  
  .card:nth-child(4) {
    background-color: #82aeff43;
  }

  .card:nth-child(5) {
    background-color: #82aeff43;
  }

  .card:nth-child(6) {
    background-color: #82aeff43;
  }

  .card:nth-child(7) {
    background-color: #82aeff43;
  }

  .card:nth-child(8) {
    background-color: #82aeff43;
  }
  .card-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .card-inner > .card_icon {
    font-size: 25px;
  }
  
  .charts {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-top: 60px;
    height: 300px;
  }