/* DailyReports.css - Enhanced styling with centered elements */

.daily-report-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    max-width: 900px;
    margin: 30px auto;
    padding: 32px 24px;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
    text-align: center;
}

.daily-report-container h1 {
    color: #2c3e50;
    font-size: 28px;
    margin-bottom: 8px;
    font-weight: 700;
    width: 100%;
}

.daily-report-container input[type="date"] {
    padding: 12px 16px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    font-size: 16px;
    width: 100%;
    max-width: 280px;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
    transition: border-color 0.3s, box-shadow 0.3s;
}

.daily-report-container input[type="date"]:focus {
    border-color: #4a90e2;
    box-shadow: 0 2px 8px rgba(74, 144, 226, 0.2);
    outline: none;
}

.report-canteen-dropdown {
    padding: 12px 16px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    font-size: 16px;
    background-color: white;
    width: 100%;
    max-width: 280px;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
    appearance: none;
    background-repeat: no-repeat;
    background-position: right 16px center;
    padding-right: 40px;
    transition: border-color 0.3s, box-shadow 0.3s;
}

.report-canteen-dropdown:focus {
    border-color: #4a90e2;
    box-shadow: 0 2px 8px rgba(74, 144, 226, 0.2);
    outline: none;
}

.daily-report-controls {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 16px;
    width: 100%;
    margin-bottom: 16px;
}

.daily-report-buttons {
    display: flex;
    gap: 16px;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
}

.daily-report-container button {
    padding: 12px 24px;
    background-color: #4a90e2;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    min-width: 150px;
    box-shadow: 0 2px 4px rgba(74, 144, 226, 0.3);
}

.daily-report-container button:hover {
    background-color: #3a7bc8;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(74, 144, 226, 0.4);
}

.daily-report-container button:active {
    transform: translateY(0);
    box-shadow: 0 2px 4px rgba(74, 144, 226, 0.3);
}

.daily-report-container button:disabled {
    background-color: #e0e0e0;
    color: #a0a0a0;
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
}

/* Error message styling */
.daily-report-container p.error-message {
    color: #e53935;
    font-size: 14px;
    margin-top: 8px;
    background-color: rgba(229, 57, 53, 0.1);
    padding: 10px 16px;
    border-radius: 6px;
    width: 100%;
    max-width: 500px;
}

/* Loading indicator */
.loading-indicator {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 3px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top-color: white;
    animation: spin 1s ease-in-out infinite;
    margin-right: 8px;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

/* Mobile responsive styles */
@media screen and (max-width: 768px) {
    .daily-report-container {
        padding: 24px 16px;
        margin: 20px auto;
    }

    .daily-report-container h1 {
        font-size: 24px;
    }

    .daily-report-controls {
        flex-direction: column;
        align-items: center;
    }

    .daily-report-container input[type="date"],
    .report-canteen-dropdown {
        max-width: 100%;
    }

    .daily-report-buttons {
        flex-direction: column;
        width: 100%;
    }

    .daily-report-container button {
        width: 100%;
    }
}

/* Smaller mobile devices */
@media screen and (max-width: 480px) {
    .daily-report-container {
        padding: 20px 12px;
        margin: 16px auto;
    }

    .daily-report-container h1 {
        font-size: 20px;
    }

    .daily-report-container button {
        padding: 10px 16px;
        font-size: 14px;
    }
}