.contact-title {
    display: relative;
}

.contact-top-area {
    margin-top: -20px;
    margin-bottom: 30px;
    display: relative;
    align-items: center;
    padding: 30px;
    position: relative;
}


.contact-canteen-dropdown {
    appearance: none;
    background-color: transparent;
    border: 2px solid black;
    float: left;
    border-radius: 8px;
    color: #333;
    cursor: pointer;
    font-size: 16px;
    padding: 10px 16px;
    width: 200px;
    outline: none;
    transition: border-color 0.3s, color 0.3s;
}

.contact-canteen-dropdown:hover,
.contact-canteen-dropdown:focus {
    border-color: #ff6000;
}

.contact-canteen-dropdown option {
    background-color: #fff;
    color: #333;
}

/* Custom arrow */
.contact-canteen-dropdown::after {
    content: '\25BC';
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    pointer-events: none;
}

.contact-add-button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
}

.contact-add-button:hover {
    background-color: #ff6000;
}

.contact-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.contact-card {
    background-color: rgba(0, 8, 255, 0.266);
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 25px;
    width: calc(33.333% - 20px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.contact-card:hover {
    transform: scale(1.05);
    transition: ease 0.3s;
}

.contact-card-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.contact-card-actions button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 18px;
}

.contact-card-actions button:hover {
    color: #007BFF;
}

.contact-popup-actions {
    display: flex;
    justify-content: space-around;
    margin-top: 25px;
}

.contact-popup-actions button {
    background-color: #ccc;
    border: none;
    cursor: pointer;
    font-size: 12px;
}

.contact-popup-actions button:hover {
    color: aqua;
}

/* CSS for the contact popup overlay */
.contact-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    /* Ensure the popup appears above other content */
}

/* CSS for the contact popup container */
.contact-popup {
    background: white;
    padding: 40px;
    border-radius: 8px;
    width: 380px;
    height: 400px;
    max-width: 600px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    /* Make the content inside the popup scrollable */
}

/* Additional CSS for form inside the popup */
.contact-popup form {
    display: flex;
    flex-direction: column;
}

.contact-popup label {
    margin-top: 15px;
}

.contact-popup input[type="text"],
.contact-popup input[type="email"],
.contact-popup textarea {
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 8px;
    font-family: 'Poppins-lights', sans-serif;
    border: 1px solid #ccc;
    border-radius: 10px;
}

.contact-popup textarea {
    resize: vertical;
}

/* Mobile Responsive Styles */
@media screen and (max-width: 768px) {

    /* Container and alignment fixes */
    .contact-title {
        font-size: 1.5rem;
        margin-bottom: 15px;
        text-align: center;
        width: 100%;
        padding: 0 15px;
        box-sizing: border-box;
    }

    /* Fix main container padding */
    div>h2.contact-title+div {
        padding: 0 15px;
        box-sizing: border-box;
    }

    .contact-top-area {
        display: flex;
        flex-direction: column;
        gap: 15px;
        margin-top: 15px;
        margin-bottom: 20px;
        width: 100%;
        padding: 0;
        box-sizing: border-box;
    }

    .contact-canteen-dropdown {
        width: 100%;
        padding: 10px;
        border-radius: 8px;
        box-sizing: border-box;
        margin: 0;
    }

    .contact-top-area .add-button {
        width: 100%;
        padding: 12px;
        margin: 0;
        box-sizing: border-box;
        border-radius: 30px;
        background-color: #800080;
        /* Purple color to match the image */
        color: white;
        border: none;
        font-weight: bold;
    }

    .contact-grid {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0;
        margin: 0;
        gap: 15px;
        box-sizing: border-box;
    }

    .contact-card {
        padding: 15px;
        border-radius: 12px;
        width: 100%;
        box-sizing: border-box;
        margin: 0;
        background-color: #c4c4ff;
        /* Light blue/purple background to match image */
    }

    .contact-card h3 {
        font-size: 1.2rem;
        font-weight: bold;
        margin-top: 0;
        margin-bottom: 10px;
    }

    .contact-card p {
        margin: 8px 0;
        line-height: 1.4;
    }

    .contact-card-actions {
        display: flex;
        justify-content: flex-end;
        gap: 10px;
        margin-top: 10px;
    }

    .contact-card-actions button {
        background-color: white;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    /* Fix for popup */
    .contact-popup-overlay {
        padding: 0;
    }

    .contact-popup {
        width: 90%;
        max-width: 350px;
        padding: 20px;
        border-radius: 10px;
    }
}