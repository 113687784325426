.admins-container {
  padding: 20px;
}

.admin-top-area {
  margin-top: -20px;
  margin-bottom: 30px;
  display: relative;
  align-items: center;
  padding: 30px;
  position: relative;
}


.admin-canteen-dropdown {
  appearance: none;
  background-color: transparent;
  border: 2px solid black;
  float: left;
  border-radius: 8px;
  color: #333;
  cursor: pointer;
  font-size: 16px;
  padding: 10px 16px;
  width: 200px;
  outline: none;
  transition: border-color 0.3s, color 0.3s;
}

.admin-canteen-dropdown:hover,
.admin-canteen-dropdown:focus {
  border-color: #ff6000;
}

.admin-canteen-dropdown option {
  background-color: #fff;
  color: #333;
}

/* Custom arrow */
.admin-canteen-dropdown::after {
  content: '\25BC';
  /* Unicode character for downward arrow */
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
}

.admins-cards {
  gap: 20px;
}

.admin-card {
  display: flex;
  background: #00ff004a;
  border: 1px solid #ddd;
  padding: 20px;
  margin-top: 20px;
  border-radius: 25px;
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.admin-card h4 {
  margin: 0 0 10px;
}

.admin-card-details{
  width: 80%
}

.admin-card-actions {
  display: flex;
  padding: 4px;
  justify-content: space-between;
  float: right;
  width: 20%;
}

/* CSS for the add popup overlay */
.admin-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure the popup appears above other content */
}

/* CSS for the add popup container */
.admin-popup {
  background: white;
  padding: 40px;
  border-radius: 8px;
  width: 60%;
  height: fit-content;
  max-width: 600px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  overflow-y: auto; /* Make the content inside the popup scrollable */
}

/* Additional CSS for form inside the popup */
.admin-popup form {
  display: flex;
  flex-direction: column;
}

.admin-popup label {
  margin-top: 10px;
}

.admin-popup input[type="text"]{
  margin-top: 5px;
  margin-bottom: 15px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

/* Mobile Responsive Styles */
@media screen and (max-width: 768px) {
  .admins-container {
    padding: 0;
  }
  
  .admin-top-area,
  .admins-cards,
  .admin-card {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
    /* Make sure all elements take full width and have no extra margins */
  }
  
  /* Set everything to the same container width */
  .canteen-dropdown,
  .admin-top-area .add-button,
  .admin-card {
    width: 100%;
    box-sizing: border-box;
  }
  
  .admin-canteen-dropdown {
    width: 100%;
    float: none;
  }
  
  .admin-top-area .add-button {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    padding: 12px;
  }
  
  .admins-cards {
    display: flex;
    flex-direction: column;
  }
  
  .admin-card {
    flex-direction: column;
    padding: 15px;
  }
  
  .admin-card-details {
    width: 100%;
  }
  
  .admin-card-actions {
    width: 100%;
    margin-top: 15px;
    float: none;
    display: flex;
    justify-content: space-between;
  }
  
  .admin-card-actions button {
    padding: 8px 15px;
    border-radius: 20px;
  }
  
  .admin-popup {
    width: 90%;
    max-width: 350px;
    padding: 20px;
  }
  
  .admin-popup h3 {
    margin-top: 0;
    margin-bottom: 15px;
  }
  
  .admin-popup label {
    display: block;
    margin-bottom: 5px;
  }
  
  .admin-popup input {
    width: 100%;
    margin-bottom: 15px;
  }
  
  .modal-actions {
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
  }
  
  .modal-actions button {
    flex: 1;
    padding: 10px;
  }
}

/* Small mobile devices */
@media screen and (max-width: 480px) {
  .admins-container h2 {
    font-size: 1.5rem;
    margin-bottom: 15px;
  }
  
  .admin-card h4 {
    font-size: 1.2rem;
    margin-bottom: 8px;
  }
  
  .admin-card p {
    margin: 5px 0;
  }
  
  .admin-popup {
    padding: 15px;
  }
  
  .modal-actions {
    margin-top: 15px;
  }
}