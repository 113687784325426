.profile-container {
  max-width: 800px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, sans-serif;
}

.profile-container h2 {
  color: #2c3e50;
  margin-bottom: 1.5rem;
  font-size: 1.8rem;
  font-weight: 600;
  text-align: center;
  padding-bottom: 0.75rem;
  border-bottom: 2px solid #f0f4f8;
}

.profile-details {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  margin-bottom: 2rem;
}

.profile-details label {
  display: block;
  margin-bottom: 0.5rem;
  color: #4a5568;
  font-weight: 500;
  font-size: 0.95rem;
}

.profile-details input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  font-size: 1rem;
  transition: all 0.2s ease;
  background-color: #f8fafc;
}
/* Profile.css */

.profile-details input[type="email"],
.profile-details input[type="text"],
.profile-details input[type="tel"] {
  width: calc(100% - 22px); /* Adjust for padding and borders */
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #e2e8f0; /* Add a border */
  border-radius: 4px; /* Rounded corners */
  font-size: 16px; /* Adjust font size */
  box-sizing: border-box; /* Include padding and border in width */
}

/* Add focus styles for better user experience */
.profile-details input[type="email"]:focus,
.profile-details input[type="text"]:focus,
.profile-details input[type="tel"]:focus {
  outline: none;
  border-color: #007bff; /* Change border color on focus */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Add a subtle shadow on focus */
}

.profile-details input:focus {
  outline: none;
  border-color: #3182ce;
  box-shadow: 0 0 0 3px rgba(49, 130, 206, 0.1);
}

.profile-details p {
  color: #718096;
  font-size: 0.9rem;
  margin-top: 0.5rem;
}

.profile-actions {
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
}

.profile-actions button {
  flex: 1;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.profile-actions button:first-child {
  background-color: #4299e1;
  color: white;
}

.profile-actions button:first-child:hover {
  background-color: #3182ce;
}

.profile-actions button:last-child {
  background-color: #edf2f7;
  color: #4a5568;
}

.profile-actions button:last-child:hover {
  background-color: #e2e8f0;
}

.canteen-preference {
  background-color: #f7fafc;
  padding: 1.5rem;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
}

.canteen-preference h3 {
  color: #2d3748;
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 1.2rem;
  font-weight: 500;
}

.canteen-preference select {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  font-size: 1rem;
  margin-bottom: 1rem;
  background-color: white;
}

.canteen-preference button {
  width: 100%;
  padding: 0.75rem;
  background-color: #4299e1;
  color: white;
  border: none;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.canteen-preference button:hover {
  background-color: #3182ce;
}

/* Modal styles */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 2rem;
  border-radius: 10px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
}

.modal-content h3 {
  color: #2c3e50;
  margin-top: 0;
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
}

.modal-content label {
  display: block;
  margin-bottom: 0.5rem;
  color: #4a5568;
  font-weight: 500;
  font-size: 0.95rem;
}
.modal-content input[type="password"] {
  width: calc(100% - 22px); /* Adjust for padding and borders */
  padding: 10px;
  margin-bottom: 15px; /* Increased margin for better spacing */
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box;
}

.modal-content input[type="password"]:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

/* Button Styles */
.modal-actions button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  margin-right: 10px; /* Add spacing between buttons */
}

.modal-actions button:last-child {
  margin-right: 0; /* Remove right margin from the last button */
}

.modal-actions button:first-child {
  background-color: #28a745; /* Green for Submit */
  color: white;
}

.modal-actions button:last-child {
  background-color: #dc3545; /* Red for Cancel */
  color: white;
}

.modal-actions button:hover {
  opacity: 0.9; /* Add a subtle hover effect */
}
.modal-actions {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.modal-actions button {
  flex: 1;
  padding: 0.75rem;
  border: none;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.modal-actions button:first-child {
  background-color: #4299e1;
  color: white;
}

.modal-actions button:first-child:hover {
  background-color: #3182ce;
}

.modal-actions button:last-child {
  background-color: #edf2f7;
  color: #4a5568;
}

.modal-actions button:last-child:hover {
  background-color: #e2e8f0;
}

/* Responsive styles */
@media (min-width: 640px) {
  .profile-details {
    grid-template-columns: 1fr 1fr;
  }
  
  .profile-details p {
    grid-column: span 2;
  }
}

@media (max-width: 480px) {
  .profile-container {
    padding: 1.5rem;
    margin: 1rem;
  }
  
  .profile-actions {
    flex-direction: column;
  }
  
  .modal-content {
    padding: 1.5rem;
  }
  
  .modal-actions {
    flex-direction: column;
  }
}