.orders-container {
  display: flex;
  flex-direction: column;
}


.order-canteen-dropdown {
  appearance: none;
  background-color: transparent;
  border: 2px solid black;
  float: left;
  border-radius: 8px;
  color: #333;
  cursor: pointer;
  font-size: 16px;
  padding: 10px 16px;
  width: 200px;
  outline: none;
  transition: border-color 0.3s, color 0.3s;
}

.order-canteen-dropdown:hover,
.order-canteen-dropdown:focus {
  border-color: #ff6000;
}

.order-canteen-dropdown option {
  background-color: #fff;
  color: #333;
}

/* Custom arrow */
.order-canteen-dropdown::after {
  content: '\25BC';
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
}

.orders-content {
  display: flex;
  justify-content: space-between;
}

.orders-table-container {
  width: 60%;
}

.order-details-container {
  width: 35%;
  border-left: 1px solid #ddd;
  padding-left: 20px;
}

.even-row {
  background-color: hsl(0, 0%, 85%);
}

.odd-row {
  background-color: #fff;
}

table tr:hover {
  background-color: #00344b2e;
}

button {
  background-color: #007bff;
  color: black;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

/* Pagination styles */
.pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
}

.pagination li {
  margin: 0 5px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.pagination li.active {
  background-color: #007bff;
  color: #fff;
  border-radius: 50px;
}

.pagination li:hover:not(.active) {
  background-color: #0000002a;
  border-radius: 50px;
}

.pagination a {
  display: block;
  padding: 8px 16px;
  text-decoration: none;
  color: #333;
}

.pagination a:hover {
  color: #ff6000;
}


.orders-top-area{
    margin-top: -50px;
    display: relative;
    align-items: center;
    padding: 30px;
    position: relative;
  background-color: rgba(0, 204, 255, 0.229);
  border-radius: 15px;
}

.search-button {
  margin-left: 10px;
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.search-button:hover {
  background-color: #0056b3;
}

@media screen and (max-width: 768px) {
  .orders-container {
    padding: 10px;
  }

  .orders-content {
    flex-direction: column;
  }

  .orders-top-area {
    flex-direction: column;
    gap: 1rem;
  }

  .tab-bar {
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }

  .tab-button {
    width: 100%;
    margin: 0.25rem 0;
  }

  .orders-table-container {
    width: 100%;
    overflow-x: auto;
  }

  .order-details-container {
    width: 100%;
    margin-top: 1.5rem;
    padding: 1rem;
  }

  .order-details table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }

  .order-details table th,
  .order-details table td {
    padding: 0.5rem;
    font-size: 0.7rem;
  }

  .pagination {
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.5rem;
    padding: 1rem 0;
  }

  .confirmation-dialog {
    width: 90%;
    max-width: none;
    padding: 1rem;
  }

  .search-bar-container {
    width: 100%;
  }

  .order-canteen-dropdown {
    width: 100%;
    margin-bottom: 1rem;
  }
}

@media screen and (max-width: 480px) {
  .orders-container h2 {
    font-size: 1.5rem;
  }

  .order-details h2 {
    font-size: 0.8rem;
  }

  .order-details h3 {
    font-size: 0.7rem;
  }

  .pagination {
    font-size: 0.875rem;
  }

  table th,
  table td {
    padding: 0.5rem 0.25rem;
    font-size: 0.75rem;
  }
}