.category-title {
  display: relative;
}

.category-top-area {
  margin-top: -20px;
  margin-bottom: 30px;
  display: relative;
  align-items: center;
  padding: 30px;
  position: relative;
}


.category-canteen-dropdown {
  appearance: none;
  background-color: transparent;
  border: 2px solid black;
  float: left;
  border-radius: 8px;
  color: #333;
  cursor: pointer;
  font-size: 16px;
  padding: 10px 16px;
  width: 200px;
  outline: none;
  transition: border-color 0.3s, color 0.3s;
}

.category-canteen-dropdown:hover,
.category-canteen-dropdown:focus {
  border-color: #ff6000;
}

.category-canteen-dropdown option {
  background-color: #fff;
  color: #333;
}

/* Custom arrow */
.category-canteen-dropdown::after {
  content: '\25BC';
  /* Unicode character for downward arrow */
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
}


.category-add-button {
  background-color: purple;
  color: white;
  font-family: 'Poppins';
  float: right;
}

.add-button:hover {
  background-color: #ff6000;
}

.category-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.category-card {
  background-color: rgba(255, 72, 0, 0.163);
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 25px;
  width: calc(33.333% - 20px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.category-card:hover {
  transform: scale(1.05);
  transition: ease 0.3s;
}

.category-card-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.category-card-actions button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
}

.category-card-actions button:hover {
  color: #007BFF;
}

.category-popup-actions {
  display: flex;
  justify-content: space-around;
  margin-top: 25px;
}

.category-popup-actions button {
  background-color: #ccc;
  border: none;
  cursor: pointer;
  font-size: 12px;
}

.category-popup-actions button:hover {
  color: aqua;
}



/* CSS for the category popup overlay */
.category-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  /* Ensure the popup appears above other content */
}

/* CSS for the category popup container */
.category-popup {
  background: white;
  padding: 40px;
  border-radius: 8px;
  width: 350px;
  height: 380px;
  max-width: 600px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  /* Make the content inside the popup scrollable */
}

/* Additional CSS for form inside the popup */
.category-popup form {
  display: flex;
  flex-direction: column;
}

.category-popup label {
  margin-top: 15px;
}

.category-popup input[type="text"],
.category-popup input[type="time"],
.category-popup input[type="file"] {
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 8px;
  font-family: 'Poppins-lights', sans-serif;
  border: 1px solid #ccc;
  border-radius: 10px;
}


.category-popup input[type="time"] {
  float: right;
}

/* Media Queries for Responsive Design */
@media (max-width: 1024px) {
  .category-card {
    width: calc(50% - 20px);
  }
}

@media (max-width: 768px) {
  .category-top-area {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    margin-top: 0;
    padding: 20px;
  }

  .category-canteen-dropdown {
    width: 100%;
    float: none;
    margin-bottom: 10px;
  }

  .category-add-button {
    width: 100%;
    margin: 0;
    position: static;
  }

  .category-grid {
    gap: 15px;
  }
}

@media (max-width: 576px) {
  .category-card {
    width: 100%;
    padding: 15px;
  }

  .category-popup {
    width: 90%;
    height: auto;
    padding: 20px;
  }

  .category-popup-actions {
    flex-direction: column;
    gap: 10px;
  }

  .category-popup-actions button {
    width: 100%;
    padding: 10px;
    margin: 5px 0;
  }

  .category-popup input[type="time"] {
    float: none;
    width: 100%;
  }
}

@media (max-width: 320px) {
  .category-top-area {
    padding: 15px;
  }

  .category-popup {
    padding: 15px;
  }
}