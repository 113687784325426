.header {
    grid-area: header;
    height: 60px;
    color: black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px 0 30px;
    box-shadow: 0 6px 7px -3px rgba(0, 0, 0, 0.35);
  }

  .icon {
    font-size: 20px;
    cursor: pointer;
    margin: 0 10px;
  }
  
  .header-left .icon {
    margin-left: 20px;
  }
  
  .header-right {
    display: flex;
    align-items: center;
  }
  
  .profile-dropdown {
    position: relative;
  }
  
  .dropdown-menu {
    width: 200px;
    position: absolute;
    top: 30px;
    right: 0;
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 10;
  }
  
  .dropdown-menu button {
    display: block;
    width: 100%;
    padding: 10px;
    border: none;
    background-color: transparent;
    text-align: left;
    cursor: pointer;
  }
  
  .menu-icon {
    display: none;
  }
  